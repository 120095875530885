import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import posthog from 'posthog-js';
import React from 'react';
import Rollbar from 'rollbar';

const rollbarConfig: Rollbar.Configuration = {
    accessToken: window.ROLLBAR_ACCESS_TOKEN,
    environment: window.ROLLBAR_ENV,
    // default to disabled
    enabled: window.ROLLBAR_ENABLED,
    transform: payload => {
        if (window.POSTHOG_ENABLED) {
            payload.posthogsessionrecordingId = posthog.get_session_id();
        }
    },
};

const rollbar = rollbarConfig.enabled ? new Rollbar(rollbarConfig) : undefined;
window.ROLLBAR_INSTANCE = rollbar;

export default function MyRollbarProvider({ children }: { children?: React.ReactNode }) {
    if (rollbar) {
        return (
            <RollbarProvider instance={rollbar}>
                <ErrorBoundary errorMessage="Error in react render">{children}</ErrorBoundary>
            </RollbarProvider>
        );
    }
    return <>{children}</>;
}
