import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import { IoClose, IoPeople } from 'react-icons/io5';
import * as MdIcons from 'react-icons/md';
import { SiMockserviceworker } from 'react-icons/si';

const Icons = {
    Accounts: IoPeople,
    // Archive: BiIcons.BiSolidTrashAlt,
    // Backspace: IoIosBackspace,
    // Barcode: FaBarcode,
    // Calendar: BiIcons.BiCalendar,
    CaretDown: AiFillCaretDown,
    CaretUp: AiFillCaretUp,
    Check: BiIcons.BiCheck,
    CheckPartial: MdIcons.MdSquare,
    ChevronLeft: BiIcons.BiChevronLeft,
    ChevronDown: BiIcons.BiChevronDown,
    ChevronRight: BiIcons.BiChevronRight,
    Clear: BiIcons.BiSolidXCircle,
    // Clone: BiIcons.BiDuplicate,
    Close: IoClose,
    // Documents: BiIcons.BiFolder,
    Dollar: BiIcons.BiDollar,
    // Download: BiIcons.BiSolidDownload,
    // DragHandle: FaGripLines,
    Edit: BiIcons.BiSolidPencil,
    // Employees: MdIcons.MdFolderShared,
    // Error: BiIcons.BiSolidErrorCircle,
    // Export: BiIcons.BiSolidCloudDownload,
    // ExternalLink: BiIcons.BiLinkExternal,
    // File: BiIcons.BiFile,
    // Filter: BiIcons.BiFilterAlt,
    // History: BiIcons.BiHistory,
    // Home: BiIcons.BiSolidHome,
    // Info: BiIcons.BiSolidInfoCircle,
    List: BiIcons.BiListUl,
    // Locked: BiIcons.BiLockAlt,
    Logout: BiIcons.BiLogOut,
    MenuDots: BiIcons.BiDotsVerticalRounded,
    MenuLines: BiIcons.BiMenu,
    Minus: BiIcons.BiMinus,
    Mock: SiMockserviceworker,
    Numpad: BiIcons.BiCalculator,
    // Package: BiIcons.BiSolidPackage,
    Percent: MdIcons.MdOutlinePercent,
    Person: BiIcons.BiUser,
    // Play: BiIcons.BiPlay,
    Plus: BiIcons.BiPlus,
    // Print: BiIcons.BiSolidPrinter,
    // PurchaseOrder: MdIcons.MdLabelImportant,
    Refresh: BiIcons.BiRefresh,
    // Reports: MdIcons.MdTimeline,
    // SadFace: BiIcons.BiSad,
    // SalesOrder: MdIcons.MdSell,
    Search: BiIcons.BiSearch,
    // Settings: MdIcons.MdSettings,
    // Tasks: MdIcons.MdTask,
    // Undo: BiIcons.BiUndo,
    // Upload: BiIcons.BiUpload,
    // Warning: IoWarningOutline,
} as const;

export default Icons;
