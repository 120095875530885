import Layout from 'components/Layout/Layout';
import authApi from 'features/auth/auth.api';
import { selectCurrentUser, selectIsAuthenticated } from 'features/auth/auth.slice';
import AccountsPage from 'pages/AccountsPage/AccountsPage';
import CallbackPage from 'pages/CallbackPage/CallbackPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import React, { Suspense, useCallback, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { usePageTitle } from 'utils/locationHelpers';

export default function AppRoutes() {
    usePageTitle('Backoffice');
    const [restoreSession] = authApi.useLazyRestoreSessionQuery();
    const isAuthed = useAppSelector(selectIsAuthenticated);
    const currentUser = useAppSelector(selectCurrentUser);

    useEffect(() => {
        if (isAuthed === true) {
            // if we are authed, or we don't know, try to restore the session
            restoreSession();
        }
    }, [isAuthed, restoreSession]);

    /** Redirect if the user is already logged in */
    const RequiresUnAuthed = useCallback(() => {
        if (isAuthed) {
            // Important - dont use `useSearchParams` for this logic
            // as it will cause the entire Routes stack to re-render whenever the url changes
            const search = new URLSearchParams(window.location.search);
            const returnUrl = search.get('returnUrl');
            return <Navigate to={returnUrl || '/'} />;
        }
        return <Outlet />;
    }, [isAuthed]);

    /** Redirect if the user is not logged in */
    const RequiresAuthed = useCallback(() => {
        if (isAuthed === false) {
            // if we are def not authed, go to the login
            // Important - dont use the `useLocation` hook for this logic
            // as it will cause the entire Routes stack to re-render whenever the url changes
            let url = window.location.pathname;
            if (window.location.search) {
                url += `${window.location.search}`;
            }
            return <Navigate to={`/login?returnUrl=${encodeURIComponent(url)}`} />;
        }
        if (isAuthed === true && currentUser != null) {
            // if we are def authed then carry on
            return <Outlet />;
        }
        // Waiting for session restore
        return <Layout isLoading={true} />;

        // TODO error state?
    }, [currentUser, isAuthed]);

    useEffect(() => {
        document.querySelector('body')?.classList.add('hide-load');
    }, []);

    return (
        <>
            <Suspense fallback={<Layout isLoading={true} />}>
                <Routes>
                    <Route element={<RequiresUnAuthed />}>
                        <Route
                            path="/login"
                            element={<LoginPage />}
                        />
                        <Route
                            path="/callback"
                            element={<CallbackPage />}
                        />
                    </Route>
                    <Route element={<RequiresAuthed />}>
                        <Route element={<Layout />}>
                            <Route
                                path="/accounts"
                                element={<AccountsPage />}
                            />
                            <Route
                                path="*"
                                element={<Navigate to={'/accounts'} />}
                            />
                        </Route>
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
}
