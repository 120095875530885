import QuoteriteWelcomeLogoSvg from 'assets/quoterite-welcome-logo.svg';
import MyButton from 'components/MyButton/MyButton';

import React, { useEffect } from 'react';
import './LoginPage.scss';

export default function LoginPage() {
    useEffect(() => {
        document.querySelector('body')?.classList.add('body--LoginPage');
        return () => document.querySelector('body')?.classList.remove('body--LoginPage');
    }, []);

    return (
        <div className="LoginPage">
            <img
                className="LoginPage__Logo"
                src={QuoteriteWelcomeLogoSvg}
            />

            <MyButton
                buttonType="Accent"
                label="Login"
                href={`${window.BACKEND_URL}/auth/login`}
            />
        </div>
    );
}
