import z from 'zod';

export const UserSchema = z.object({
    principalId: z.string(),
    email: z.string(),
});

export type User = z.infer<typeof UserSchema>;

export function getUserInitials(user?: User) {
    const tokens = user?.email.split('@')[0]?.split('.') ?? [];
    const first = tokens.length > 0 ? tokens[0].charAt(0) : '';
    const last = tokens.length > 1 ? tokens[tokens.length - 1].charAt(0) : '';
    return `${first}${last}`.toUpperCase();
}
