import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useUrlQueryState(
    key: string,
    defaultValue = '',
): [string, (val: string, options?: { replace?: boolean }) => void] {
    // url params
    const [params, setParams] = useSearchParams({});

    const setValue = useCallback(
        (val: string, options?: { replace?: boolean }) => {
            if (!val || val === defaultValue) {
                params.delete(key);
            } else {
                params.set(key, val);
            }
            setParams(params, options);
        },
        [defaultValue, key, params, setParams],
    );

    const currentValue = params.get(key) || defaultValue;
    return [currentValue, setValue];
}
