import Drawer from '@mui/material/Drawer';
import Icons from 'Icons';
import QuoteriteIconWithNameSvg from 'assets/quoterite-icon-with-name.svg';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import { useBreakpoints } from 'providers/Breakpoints';
import React from 'react';
import { IconType } from 'react-icons';
import { Link, useLocation } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import './MainNav.scss';

export default function MainNav({
    isLoading = false,
    isMenuOpen = false,
    closeMenu,
}: {
    isLoading?: boolean;
    isMenuOpen?: boolean;
    closeMenu?: () => void;
}) {
    const breakpoints = useBreakpoints();

    return (
        <Drawer
            className="MainNav"
            variant={breakpoints.isSmallDown ? 'temporary' : 'permanent'}
            anchor="left"
            open={isMenuOpen}
            onClose={closeMenu}
        >
            <div className="MainNav__Logo">
                <img
                    className="MainNav__Logo__DefaultImage"
                    src={QuoteriteIconWithNameSvg}
                />
            </div>

            {isLoading ? (
                <MyLinearProgress className="MainNav__LoadingProgress" />
            ) : (
                <>
                    <NavSection title="">
                        <NavLinkItem
                            Icon={Icons.Accounts}
                            label="Accounts"
                            to={`/accounts`}
                            onClick={closeMenu}
                        />
                        <NavLinkItem
                            Icon={Icons.Accounts}
                            label="Another page"
                            to={`/page2`}
                            onClick={closeMenu}
                        />
                        <NavLinkItem
                            Icon={Icons.Accounts}
                            label="And another"
                            to={`/page3`}
                            onClick={closeMenu}
                        />
                    </NavSection>
                </>
            )}
        </Drawer>
    );
}

function NavSection({ title = '', children }: { title: string; children?: React.ReactFragment }) {
    return (
        <ul className="MainNav__NavSection">
            {title && <li className="MainNav__NavSection__Title">{title}</li>}
            {children}
        </ul>
    );
}

function NavLinkItem({
    Icon = Icons.List,
    label = '',
    to = '',
    onClick,
}: {
    Icon?: IconType;
    label: React.ReactNode;
    to: string;
    onClick?: () => void;
}) {
    const location = useLocation();
    const rootPath = `/${location.pathname.split('/')[1]}`;
    const isActive = rootPath === to;

    return (
        <li className="MainNav__NavLinkItem">
            <Link
                className={coalesceClassNames('MainNav__NavLinkItem__Link', isActive && 'active')}
                to={to}
                onClick={onClick}
            >
                {Icon && <Icon className="icon" />}
                <span className="label">{label}</span>
            </Link>
        </li>
    );
}

// function AbsLinkItem({
//     Icon = Icons.List,
//     label,
//     to = '',
// }: {
//     Icon?: IconType;
//     label: React.ReactNode;
//     to: string;
// }) {
//     return (
//         <li className="MainNav__NavLinkItem">
//             <a
//                 className={coalesceClassNames('MainNav__NavLinkItem__Link')}
//                 href={to}
//             >
//                 {Icon && <Icon className="icon" />}
//                 <span className="label">{label}</span>
//             </a>
//         </li>
//     );
// }
