import PageHeader from 'components/PageHeader/PageHeader';
import React from 'react';
import { Outlet } from 'react-router';
import { usePageTitle } from 'utils/locationHelpers';

export default function AccountsPage() {
    usePageTitle('Accounts');
    return (
        <>
            <PageHeader title="Accounts" />
            <Outlet />
        </>
    );
}
