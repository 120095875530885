import { Tooltip } from '@mui/material';
import Icons from 'Icons';
import QuoteriteHeaderLogo from 'assets/quoterite-icon-with-name-black.svg';
import MyButton from 'components/MyButton/MyButton';
import MyMenuButton from 'components/MyMenuButton/MyMenuButton';
import Env from 'config/Env';
import { clearAuth, selectCurrentUser } from 'features/auth/auth.slice';
import { getUserInitials } from 'features/auth/models/User';
import { useBreakpoints } from 'providers/Breakpoints';
import React from 'react';
import { api } from 'services/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './AppHeader.scss';

export default function AppHeader({ onMenuClick }: { onMenuClick: () => void }) {
    const user = useAppSelector(selectCurrentUser);
    const dispatch = useAppDispatch();

    const signOut = () => {
        dispatch(clearAuth({ didSessionExpire: false }));
        dispatch(api.util.resetApiState());
    };

    const breakpoints = useBreakpoints();

    return (
        <header className="AppHeader">
            <nav className="AppHeader__NavLeft">
                {breakpoints.isSmallDown && (
                    <>
                        <MyButton
                            className="AppHeader__NavLeft__MobileMenuButton"
                            IconRight={Icons.MenuLines}
                            onClick={onMenuClick}
                            buttonType="Primary"
                        />

                        <img
                            className="AppHeader__NavLeft__Logo--DefaultImage"
                            src={QuoteriteHeaderLogo}
                            alt="Logo"
                        />
                    </>
                )}
                {Env.MSW_ENABLED && (
                    <Tooltip
                        title={
                            <div className="AppHeader__NavLeft__MockBadge__TooltipContent">
                                <strong>Mock service worker is enabled.</strong>
                                <br /> Just think about that if you're getting errors.
                            </div>
                        }
                        placement="bottom"
                        arrow={true}
                    >
                        <div className="AppHeader__NavLeft__MockBadge">
                            <Icons.Mock /> MSW: <strong>On</strong>
                        </div>
                    </Tooltip>
                )}
            </nav>
            <nav className="AppHeader__NavRight">
                <MyMenuButton
                    className="AppHeader__NavRight__UserMenuButton"
                    buttonText={getUserInitials(user)}
                    buttonType="Secondary"
                    title={`Signed in as ${user?.email}`}
                    menuItems={[
                        {
                            label: 'Sign out',
                            buttonType: 'None',
                            IconLeft: Icons.Logout,
                            onClick: signOut,
                        },
                    ]}
                />
            </nav>
        </header>
    );
}
