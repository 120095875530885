import { rest } from 'msw';

import Env from 'config/Env';
import { User } from './models/User';

const BASE_URL = `${Env.API_BASE_URL}`;

export const authHandlers = [
    // Get work items by sales order id
    rest.get(`${BASE_URL}/auth/me`, (req, res, ctx) => {
        const user: User = {
            principalId: '1',
            email: 'email@here.there',
        };
        const data = {
            user,
        };
        return res(ctx.delay(500), ctx.status(200), ctx.json(data));
    }),
];
