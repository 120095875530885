import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import 'config/i18n';

import reportWebVitals from 'reportWebVitals';
import { initMockServiceWorker } from 'mocks';

import { PostHogProvider } from 'utils/posthogHelpers';
import { PostHogConfig } from 'posthog-js';

const options: Partial<PostHogConfig> = {
    api_host: window.POSTHOG_HOST,
};

initMockServiceWorker();

ReactDOM.render(
    <React.StrictMode>
        <PostHogProvider
            enabled={window.POSTHOG_ENABLED}
            apiKey={window.POSTHOG_API_KEY}
            options={options}
        >
            <App />
        </PostHogProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
