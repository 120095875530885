import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import authApi from 'features/auth/auth.api';
import { clearAuth } from 'features/auth/auth.slice';
import useUrlQueryState from 'hooks/useUrlQueryState';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import './CallbackPage.scss';

export default function CallbackPage() {
    useEffect(() => {
        document.querySelector('body')?.classList.add('body--CallbackPage');
        return () => document.querySelector('body')?.classList.remove('body--CallbackPage');
    }, []);

    const [code] = useUrlQueryState('code');
    const [state] = useUrlQueryState('state');

    const query = authApi.useCallbackQuery({ code, state });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (query.isError) {
            dispatch(clearAuth({ didSessionExpire: true }));
            navigate('/login');
        }
    }, [dispatch, navigate, query.isError]);

    return (
        <div className="CallbackPage">
            {query.isLoading ? (
                <MyLinearProgress delay={0} />
            ) : query.isError ? (
                <ErrorContent />
            ) : (
                <>Success</>
            )}
        </div>
    );
}
