import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { authApi } from 'features/auth/auth.api';
import posthog from 'posthog-js';
import type { RootState } from 'store/store';
import { User } from './models/User';

type AuthState = {
    user: User | undefined;
    isAuthenticated: boolean; // if null it means we aren't sure if we are authed or not
    didSessionExpire: boolean;
};
const getInitialState = (): AuthState => {
    return {
        user: undefined,
        isAuthenticated: !!localStorage.getItem('token'),
        didSessionExpire: false,
    };
};

const handleClearAuth = (state: AuthState, didSessionExpire: boolean) => {
    const newState = getInitialState();
    newState.didSessionExpire = state.isAuthenticated === true && didSessionExpire;
    newState.isAuthenticated = false;
    localStorage.removeItem('token');

    if (window.POSTHOG_ENABLED) {
        posthog.reset();
    }
    return newState;
};

const slice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        clearAuth(state, action: PayloadAction<{ didSessionExpire: boolean }>) {
            return handleClearAuth(state, action.payload.didSessionExpire);
        },
    },
    extraReducers: builder => {
        builder
            // Login succeeded
            .addMatcher(authApi.endpoints.callback.matchFulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.didSessionExpire = false;
                localStorage.setItem('token', action.payload);
            })
            // Login failed
            .addMatcher(authApi.endpoints.callback.matchRejected, state => {
                state.isAuthenticated = false;
                state.didSessionExpire = false;
                localStorage.removeItem('token');
            })

            // Restore session succeeded
            .addMatcher(authApi.endpoints.restoreSession.matchFulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.user = action.payload.user;
            });
    },
});

export const { clearAuth } = slice.actions;

export default slice.reducer;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectDidSessionExpire = (state: RootState) => state.auth.didSessionExpire;
export const selectCurrentUser = (state: RootState) => state.auth.user;
