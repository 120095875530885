import axios from 'axios';

export default function createAxiosApi(baseURL: string) {
    const api = axios.create({
        baseURL,
    });

    api.defaults.headers.common.Accept = 'application/json';
    api.defaults.headers.post['Content-Type'] = 'application/json';
    api.defaults.headers.put['Content-Type'] = 'application/json';

    api.interceptors.request.use(
        config => {
            const token = localStorage.getItem('token');
            if (token && config?.headers) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => Promise.reject(error),
    );

    api.interceptors.response.use(
        response => response.data, // return data object
        error => Promise.reject(error),
    );
    return api;
}
