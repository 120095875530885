import { User, UserSchema } from 'features/auth/models/User';
import posthog from 'posthog-js';
import { api } from 'services/api';
import z from 'zod';

export const authApi = api.injectEndpoints({
    endpoints: build => ({
        callback: build.query<string, { code: string; state: string }>({
            query: ({ code, state }) => ({
                url: '/auth/callback',
                method: 'GET',
                params: {
                    code,
                    state,
                },
            }),
            transformResponse: response => {
                const schema = z.object({
                    token: z.string(),
                });
                return schema.parse(response).token;
            },
        }),

        restoreSession: build.query<
            {
                user: User;
            },
            void
        >({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                // Current user
                const userResult = await fetchWithBQ({
                    url: `/me`,
                    method: 'GET',
                });
                if (userResult.error) {
                    return {
                        error: userResult.error,
                    };
                }

                const user = UserSchema.parse(userResult.data);

                if (window.POSTHOG_ENABLED) {
                    posthog.identify(`${user.principalId}`);
                }
                // user
                return {
                    data: {
                        user,
                    },
                };
            },
        }),

        forgotPassword: build.mutation<void, string>({
            async queryFn(email, _queryApi, _extraOptions, fetchWithBQ) {
                const csrfResult = await fetchWithBQ({
                    url: `/old/auth/generate-token`,
                    method: 'GET',
                });

                if (csrfResult.error) {
                    return {
                        error: csrfResult.error,
                    };
                }

                await fetchWithBQ({
                    url: `/old/auth/forgot-password`,
                    method: 'POST',
                    data: {
                        email,
                    },
                });
                return {
                    data: undefined,
                };
            },
        }),

        resetPassword: build.mutation<
            void,
            {
                token: string;
                password: string;
                confirm_password: string;
            }
        >({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                const csrfResult = await fetchWithBQ({
                    url: `/old/auth/generate-token`,
                    method: 'GET',
                });

                if (csrfResult.error) {
                    return {
                        error: csrfResult.error,
                    };
                }

                await fetchWithBQ({
                    url: `/old/auth/reset-password`,
                    method: 'POST',
                    data: args,
                });
                return {
                    data: undefined,
                };
            },
        }),
    }),
});

export default authApi;
