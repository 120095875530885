import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
    typography: {
        fontFamily: '"Inter", sans-serif',
        fontWeightLight: 100,
        fontWeightRegular: 400,
        fontWeightBold: 700,
        // fontDisplay: 'swap',
        button: { fontWeight: 700 },
        fontSize: 13,
    },
    transitions: {
        duration: {},
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true, // turn off default btn shadows
            },

            styleOverrides: {
                root: {
                    lineHeight: '1.5',
                    padding: '16px 21px 16px',
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0,0,0,0.25)',
                },
                invisible: {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                notched: false,
            },
            styleOverrides: {
                input: {
                    padding: '22px 16px 9px;',
                },
            },
        },
    },
    palette: {
        background: {
            default: undefined,
        },
        primary: {
            main: '#009eda',
            light: '#e5f3fb',
            dark: '#004c6b',
        },
        secondary: {
            main: '#2B323F',
            light: '#a5b2ba',
            dark: '#2B323F',
        },
        error: {
            main: '#d83747',
            light: '#ff6272',
            dark: '#ff6272',
        },
    },
});

export default Theme;
